import React from 'react'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog';
import { MdClose } from 'react-icons/md'
import { StaticImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'

import S1Logo from '../../../assets/images/svg/S1-MME-logo.svg'
import { URL } from '../../../support';
import AppStoreBig from '../../../assets/images/svg/app-store-big.svg'
import GooglePlayBig from '../../../assets/images/svg/google-play-big.svg'
// import MmeQrCode from '../../../assets/images/svg/qr-code.svg'

const MmeQrCode = loadable(() => import('../../../assets/images/svg/qr-code.svg'))
const MmeModal = ({ open, handleClose }) => {
	if (typeof window !== 'undefined' && (navigator.userAgent.indexOf('Android') !== -1 || navigator.userAgent.indexOf('iPhone') !== -1)) {
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				PaperProps={{style: { borderRadius: 16 }}}
				sx={{
					"& .MuiDialog-container": {
						"& .MuiPaper-root": {
							width: "100%",
							maxWidth: "340px",  // Set your width here
						},
					},
				}}
			>
				<ModalForMobile>
					<CloseIcon onClick={handleClose}>
						<MdClose />
					</CloseIcon>
					<LogoForMobile>
						<S1Logo />          
					</LogoForMobile>
					<TextForMobile>
						We are now part of MONEYME. Check your credit score by downloading the MONEYME app now!
					</TextForMobile>
					{typeof window !== 'undefined' && navigator.userAgent.indexOf('iPhone') !== -1 ? 
						<AppLink
							className="logo"
							rel="noopener noreferrer"
							target="_blank"
							href={URL.MME_APP_STORE}
						>
							<AppStoreBig />
						</AppLink>
					:
						<AppLink
							className="logo"
							rel="noopener noreferrer"
							target="_blank"
							href={URL.MME_GOOGLE_PLAY}
						>
							<GooglePlayBig />
						</AppLink>
					}
				</ModalForMobile>
			</Dialog>
		)
	} else {
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				PaperProps={{style: { borderRadius: 16 }}}
				sx={{
					"& .MuiDialog-container": {
						"& .MuiPaper-root": {
							width: "100%",
							maxWidth: "640px",  // Set your width here
						},
					},
				}}
			>
				<ModalForDesktop>
					<CloseIcon onClick={handleClose}>
						<MdClose />
					</CloseIcon>
					<Heading>
						<Icon>
							<StaticImage src='../../../assets/images/qr-scan.png' alt="qr-scan" placeholder='none'/>          
						</Icon>
						<div className='scanHeading'>Scan to download the app</div>
					</Heading>
					<ContentForDesktop>
						<QrCode>
							<MmeQrCode />          
						</QrCode>
						<LogoAndText>
							<LogoForDesktop>
								<S1Logo />          
							</LogoForDesktop>
							<TextForDesktop>
								We are now part of MONEYME. Check your credit score by downloading the MONEYME app now!
							</TextForDesktop>
						</LogoAndText>
					</ContentForDesktop>
				</ModalForDesktop>
			</Dialog>
		)
	}
}
const QrCode = styled.div`
	width: 115px !important;
	margin-right: 20px;
`
const LogoAndText = styled.div`
  display: flex;
	flex-direction: column;
`
const LogoForDesktop = styled.div`
	svg {
		width: 120px;
	}
`
const TextForDesktop = styled.p`
	font-size: 16px;
	color: #000000;
	margin: 0;
	max-width: 420px;
`
const ContentForDesktop = styled.div`
  display: flex;
	flex-direction: row;
	justify-content: space-between;
  align-items: center;
	margin: 20px 0 25px 0;

`
const ModalForDesktop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #E9EAF8;
  padding: 28px 42px;
  @media screen and (max-width: 768px) {

  };
`
const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
`
const Icon = styled.div`
  margin-right: 20px;
`
const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .scanHeading {
    font-size: 34px;
    font-family: 'Work Sans', sans-serif;
		font-weight: 700;
		color: #000000;
  }
  p {
    margin: 10px 0;
  }
`
const ModalForMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #E9EAF8;
  padding: 27px 26px;
  @media screen and (max-width: 768px) {

  };
`
const LogoForMobile = styled.div`
	svg {
		width: 170px;
	}
`
const TextForMobile = styled.p`
	font-size: 16px;
	color: #000000;
	margin: 15px 0 17px 0;
`
const AppLink = styled.a`

`
export default MmeModal